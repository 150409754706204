import { Suspense } from "react";
import type { AppProps } from "types";
import { Route, Switch } from "react-router-dom";
import { Loading } from "@perimeter_81/ui";
import { lazyWithRetry } from "tools/lazyWithRetry";
import { RedirectByTenantState } from "./RedirectByTenantState";
import type { Props } from "./RedirectByTenantState";
import { CheckpointProvider } from "./CheckpointProvider";
import ActivatingAccount from "../../components/checkpoint/ActivatingAccount";

const Container = lazyWithRetry(
  () => import("components/checkpoint/Container")
);
const ChoosePassword = lazyWithRetry(
  () => import("components/checkpoint/ChoosePassword")
);
const ActivatingLicense = lazyWithRetry(
  () => import("components/checkpoint/ActivatingLicense")
);
const CheckpointNoReadOnly = lazyWithRetry(
  () => import("components/checkpoint/NoReadOnly")
);
const CreateWorkspace = lazyWithRetry(
  () => import("components/checkpoint/CreateWorkspace")
);
const CheckPointAccessFailed = lazyWithRetry(
  () => import("components/checkpoint/AccessFailed")
);

const basePath = "/cp";
export const createWorkspace = `${basePath}/create-workspace`;
export const choosePassword = `${basePath}/choose-password`;
export const activatingLicense = `${basePath}/activating-license`;
export const activatingAccount = `${basePath}/activating-account`;
export const noReadOnly = `${basePath}/no-read-only`;
export const accessFailed = `${basePath}/access-failed`;

export const CheckpointRouter = (props: Props & { appProps: AppProps }) => {
  return (
    <CheckpointProvider appProps={props.appProps}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Container>
            <Route path={createWorkspace}>
              <CreateWorkspace />
            </Route>
            <Route path={choosePassword}>
              <ChoosePassword />
            </Route>
            <Route path={activatingLicense}>
              <ActivatingLicense />
            </Route>
            <Route path={activatingAccount}>
              <ActivatingAccount />
            </Route>
            <Route path={noReadOnly}>
              <CheckpointNoReadOnly />
            </Route>
            <Route path={accessFailed}>
              <CheckPointAccessFailed />
            </Route>
            <RedirectByTenantState {...props} />
          </Container>
        </Switch>
      </Suspense>
    </CheckpointProvider>
  );
};
