import { call, put, takeEvery } from "redux-saga/effects";
import * as actions from "./agentsBuildsGetActions";
import platformList from "data/downloads";
import { api, getAuthHeaders, createApiHooks } from "tools/apiConfigurator";
import { getCurrentSession } from "tools/localStorage";

function* agentsBuildsGet(action) {
  try {
    const session = getCurrentSession();
    const { data: platforms } = yield api
        .get("agent-builds", {
          headers: getAuthHeaders(),
          hooks: session ? createApiHooks(true) : undefined,
        })
        .json();

    let result = platformList.map((platform) => {
      let { name, packageType } = platform;
      if (platforms[name]?.[packageType]) {
        return { ...platform, ...platforms[name][packageType] };
      }
      return platform;
    });

    yield put(actions.requestSuccess(result));

    if (action.resolve) {
      yield call(action.resolve, result);
    }
  } catch (error) {
    yield put(actions.requestFailure(error));

    if (action.reject) {
      yield call(action.reject, error);
    }
  }
}

function* saga() {
  yield takeEvery(actions.AGENTS_BUILDS_GET_REQUEST, agentsBuildsGet);
}

export default saga;
