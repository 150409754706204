import { Redirect } from "react-router-dom";
import {
  accessFailed,
  activatingAccount,
  activatingLicense,
  choosePassword,
  createWorkspace,
  noReadOnly,
} from "./index";
import { useSelector } from "react-redux";
import { SubscriptionStatus } from "../../tools/cp-middleware-adapter";
import {
  automaticLicenseFlow,
  checkpointLicenseExpiredCode,
  checkpointLicenseInvalidCode,
} from "data/constants";
import { ROOT } from "../paths";
import paths from "routes/organization/organizationPaths";

export interface Props {
  isCPReadOnly: boolean;
  isCpDemoTenant: boolean;
  hasCPWriteAccess: boolean;
  subscriptionStatus: SubscriptionStatus;
}

export const RedirectByTenantState = (props: Props) => {
  const {
    isCPReadOnly,
    hasCPWriteAccess,
    isCpDemoTenant,
    subscriptionStatus,
  } = props;

  const { userData } = useSelector((s: any) => s.cpCheckTenantGet);
  const {
    tenantExists,
    userExists,
    checkpointLicenseErrors,
    manuallyActivatingLicense,
    isCreatingNewWorkspace,
  } = userData || {};

  const cpReadOnlyAndNotDemoTenant = !isCpDemoTenant && isCPReadOnly;
  const noData = !userData;
  const noTenant = !tenantExists;
  const tenantWithoutUser = tenantExists && !userExists;

  if (cpReadOnlyAndNotDemoTenant) {
    return <Redirect to={noReadOnly} />;
  }

  if (
    noData ||
    (noTenant && !hasCPWriteAccess) ||
    (automaticLicenseFlow &&
      (checkpointLicenseErrors === checkpointLicenseExpiredCode ||
        subscriptionStatus === SubscriptionStatus.FAIL))
  ) {
    return <Redirect to={accessFailed} />;
  }

  if (noTenant) {
    return <Redirect to={createWorkspace} />;
  }

  if (tenantWithoutUser) {
    return <Redirect to={choosePassword} />;
  }

  if (isCreatingNewWorkspace) {
    return <Redirect to={activatingAccount} />;
  }

  if (
    checkpointLicenseErrors === checkpointLicenseInvalidCode &&
    manuallyActivatingLicense
  ) {
    return <Redirect to={activatingLicense} />;
  }

  return <Redirect to={paths(ROOT).DASHBOARD} />;
};
