import ky from "ky";
import { isCheckpointDomain } from "data/isCheckpointDomain";

export let env = process.env;

export async function injectEnvsScript() {
  try {
    env = {
      ...env,
      ...(await ky
        .get(
          `${isCheckpointDomain ? process.env.REACT_APP_ENVS_URL : ""}/envs.js`
        )
        .json()),
    };
  } catch {}
}