import { Action } from "types";
import {
  ActionTypes,
  ValidateTenantSKUsAction,
  State,
  ValidateTenantSKUsFailureAction,
} from "./types";
import { ApplicationName } from "types/CPIPAppIntegration";

const ACTION_HANDLERS = {
  [ActionTypes.CP_CHECK_TENANT_SKU_GET_REQUEST_FAILURE]: (
    state: State,
    action: ValidateTenantSKUsFailureAction
  ) => ({
    ...state,
    loading: false,
    error: action.error,
    tenantSKUs: {
      [ApplicationName.SaasApi]: false,
    },
  }),
  [ActionTypes.CP_CHECK_TENANT_SKU_GET_DATA]: (
    state: State,
    action: ValidateTenantSKUsAction
  ) => ({
    ...state,
    loading: false,
    error: null,
    tenantSKUs: { ...state.tenantSKUs, ...action.tenantSKUs },
  }),
};

const initialState: State = {
  loading: false,
  error: null,
  tenantSKUs: {
    [ApplicationName.SaasApi]: false,
  },
};

export default function cpCheckTenantSKUsGetReducer(
  state: State = initialState,
  action: Action
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
