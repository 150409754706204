import { links } from "data/constants";

export function isValidUrl(url, validDomain) {
  try {
    return new URL(url).origin.endsWith(validDomain);
  } catch {
    return false;
  }
}

export function shouldHideOnRoute(pathname, hiddenRoutes) {
  for (const route in hiddenRoutes) {
    if (hiddenRoutes[route] === pathname) {
      return true;
    }
  }
}

export function isRootLocation(location) {
  let { pathname, search, hash, state } = location;
  return pathname === "/" && !search && !hash && !state;
}

export function iconSrc(iconName) {
  return `${links.imagesBaseUrl}/icons2/${iconName}.svg`;
}
