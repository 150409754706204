import * as actions from "./accountGetActions";
import { ROOT_ACCOUNT_GET_REQUEST_SUCCESS } from "store/api/rootAccountGet/rootAccountGetActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";

import { resources, access, hasAccess } from "tools/permissions";
import { ffKeys } from "tools/featureFlag";

function addAccessGetStartedFlow(data) {
  const { subscriptionStatus, featureFlagKeys, permissions } = data.account;
  data.account.accessGetStartedFlow =
    Boolean(subscriptionStatus) &&
    featureFlagKeys.includes(ffKeys.GET_STARTED) &&
    hasAccess(resources.ACCOUNT, access.WRITE) &&
    permissions.includes(resources.GET_STARTED);

  return data;
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.PROFILE_ACCOUNT_GET_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
  }),
  [actions.PROFILE_ACCOUNT_GET_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: addAccessGetStartedFlow(action.data),
  }),
  [actions.PROFILE_ACCOUNT_GET_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
  [ROOT_ACCOUNT_GET_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: addAccessGetStartedFlow(action.data),
    };
  },

  [CURRENT_CHILD_ORGANIZATION_SET]: () => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function accountGetReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
