const whiteLabelData = {
  name: "perimeter81",
  billing: {
    enable: true,
  },
  onBoard: {
    enable: true,
  },
  management: {
    enable: true,
  },
  ssoSW: {
    enable: false,
  },
  support: {
    enable: true,
  },
  dnsFiltering: {
    enable: true,
  },
  splunkIntegration: {
    enable: true,
  },
  azureSentinelIntegration: {
    enable: true,
  },
  amazonS3Integration: {
    enable: true,
  },
  killSwitch: {
    enable: true,
  },
  auth2: {
    enable: true,
  },
  general: {
    supportEmail: "sase-support@checkpoint.com",
    knowledgeBaseUrl: "https://support.perimeter81.com/",
  },
};

export function useWhitelabel() {
  return whiteLabelData;
}
