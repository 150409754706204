import { ValidateTenantSKUs } from "../../../tools/cp-middleware-adapter";
import {
  BaseRequestFailureAction,
  BaseRequestSuccessAction,
} from "../../../types";

export enum ActionTypes {
  CP_CHECK_TENANT_SKU_GET_REQUEST_FAILURE = "CP_CHECK_TENANT_SKU_GET_REQUEST_FAILURE",
  CP_CHECK_TENANT_SKU_GET_DATA = "CP_CHECK_TENANT_SKU_GET_DATA",
}
export interface State {
  loading: boolean;
  error: string | null;
  tenantSKUs: ValidateTenantSKUs;
}

export type ValidateTenantSKUsFailureAction = BaseRequestFailureAction & {
  error: string;
};

export type ValidateTenantSKUsAction = BaseRequestSuccessAction<ValidateTenantSKUs> & {
  tenantSKUs: ValidateTenantSKUs;
};

export type RequestFailureActionCreator = (
  error: string
) => BaseRequestFailureAction<string>;

export type setTenantSKUsActionCreator = (
  tenantSKUs: ValidateTenantSKUs
) => BaseRequestSuccessAction<ValidateTenantSKUs>;
