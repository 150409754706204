import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface URLTrackerContextProps {
  history: string[];
}

const URLTrackerContext = createContext<URLTrackerContextProps | undefined>(
  undefined
);

export const URLTrackerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory((prevHistory) => {
      const updatedHistory = [...prevHistory, window.location.href];
      // Keep only the last two URLs
      return updatedHistory.slice(-2);
    });
  }, [location]);

  return (
    <URLTrackerContext.Provider value={{ history }}>
      {children}
    </URLTrackerContext.Provider>
  );
};

export const useURLTracker = (): URLTrackerContextProps => {
  const context = useContext(URLTrackerContext);
  if (!context) {
    throw new Error("useURLTracker must be used within a URLTrackerProvider");
  }
  return context;
};
