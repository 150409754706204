import ky, { ResponsePromise } from "ky";
import { env } from "env";
import { ApplicationName } from "types/CPIPAppIntegration";
interface PartnerSSO {
  token: string;
  tenantId: string;
}
export interface PartnerCheck {
  tenantExists: boolean;
  userExists: boolean;
  checkpointLicenseExists: boolean;
  chargebeeLicenseExists: boolean;
  checkpointLicenseErrors?: number;
  manuallyActivatingLicense?: boolean;
  isCreatingNewWorkspace?: boolean;
}

export interface CreateUser {
  tenantId: string;
  token: string;
}

export enum SubscriptionStatus {
  PENDING = "pending",
  FAIL = "fail",
  SUCCESS = "success",
}

interface HeartbeatPayload {
  sessionId: string;
}
export interface ValidateTenantSKUPayload {
  appList: ApplicationName[];
}

export type ValidateTenantSKUs = {
  [key in ApplicationName]: boolean;
};

export function validateTenantSKU(
  body: ValidateTenantSKUPayload
): Promise<{ checkRes: boolean[] }> {
  return ky
    .post(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/skusVerification`,
        {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
    )
    .json();
}

export function heartbeat(body: HeartbeatPayload): void {
  ky.post(
    new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/heartbeat`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  ).json();
}

export function partnerCheck(): Promise<PartnerCheck> {
  return ky
    .get(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/status`,
        {
          credentials: "include",
        }
      )
    )
    .json();
}

export function partnerSSO(): Promise<PartnerSSO> {
  return ky
    .get(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/sso`, {
        credentials: "include",
      })
    )
    .json();
}

export function createUser(body: string): Promise<CreateUser> {
  return ky
    .post(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/users`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      })
    )
    .json();
}

export function createWorkspace(body: {
  password: string;
  tenantId: string;
}): Promise<void> {
  return ky
    .post(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
    )
    .json();
}

export function checkSubscriptionStatus(): Promise<{
  status: SubscriptionStatus;
}> {
  return ky
    .get(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/subscription/status`,
        { credentials: "include" }
      ),
      {
        retry: 0,
      }
    )
    .json();
}

export function triggerTenantEvent(appName: string): ResponsePromise {
  return ky.post(
    new Request(
      `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/event`,
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ appName }),
      }
    ),
    {
      retry: 0,
    }
  );
}

export async function getDataTypesFromCP(): Promise<ResponsePromise> {
  try {
    return await ky
      .get(
        new Request(
          `${env.REACT_APP_CLOUDINFRA_BASE_URL}/app/dlp/v2/config/720bfa26-b3b5-48aa-ac2e-a4d22cad2bbf/dts/predefined`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
        {
          retry: 0,
          timeout: 90000,
        }
      )
      .json();
  } catch (e) {
    return {
      status: "SUCCESS",
      id: "3507cdbc-0248-40e0-b106-e3ef06db991c",
      dataTypes: [
        {
          id: "408f49a3-5612-4fdc-a3f0-a963a3878f64",
          name: "Source Code",
          type: "GROUP",
          createType: "PRE_DEFINED",
          internal: false,
        },
        {
          id: "2df6f5b6-961f-4230-a023-67add6c44b9b",
          name: "Source Code - ASP",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "701dcb86-d7dd-468d-97de-f55edc8140b4",
          name: "Source Code - ASP dot NET",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "218f42ba-fde6-4434-a0d2-766bc56bb09c",
          name: "Source Code - C Sharp",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "844191d7-0315-46f5-a84d-133c9a8fc2b1",
          name: "Source Code - C or CPP",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "cdca8f0f-d813-49f4-918f-aea086aa7f6e",
          name: "Source Code - FORTRAN",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "f7481ba5-997e-4aa9-a62f-05be5c185874",
          name: "Source Code - JAVA",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "2246f0b5-51fb-403b-8370-ce9856255c3f",
          name: "Source Code - MATLAB",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
        {
          id: "ae3473be-5bdf-4afa-b0ee-85d72feb9104",
          name: "Source Code - Visual Basic",
          type: "WEIGHTED_WORDS",
          createType: "PRE_DEFINED",
          internal: true,
        },
      ],
    };
  }
}
