import qs from "qs";
import { WebAuth } from "auth0-js";
import ky, { HTTPError, TimeoutError } from "ky";
import m from "moment";
import {
  initWSConnection,
  authWSConnection,
  closeWSConnection,
  setWSAccessToken,
} from "tools/ws";
import {
  getCurrentSession,
  setCurrentSession,
  removeSession,
  setLogoutReason,
} from "tools/localStorage";
import { signInUrl } from "routes/urlGenerators";
import store from "store/store";
import { createBroadcastChannel } from "tools/broadcastChannel";
import { once } from "tools/function";
import { showErrorMessage } from "tools/apiErrorsHelper";
import { setUserPermissions } from "tools/permissions";
import { env } from "env";
import { httpHeaders, userInitiatedLogoutReason } from "data/constants";
import { isCheckpointDomain } from "data/isCheckpointDomain";


function auth0Logout({ reason = userInitiatedLogoutReason }) {
  setLogoutReason(reason);

  return new WebAuth({
    clientID: store.getState().checkTenantGet.data.auth0.webClientId,
    domain: env.REACT_APP_AUTH0_DOMAIN,
  }).logout({
    returnTo: new URL(signInUrl(), window.location.origin).toString(),
  });
}

export async function performLogout({ reason, redirectToSignIn = true } = {}) {
  let session = getCurrentSession();
  if (session) {
    await baseApi.delete("v1/auth/token", {
      headers: getAuthHeaders(),
    });
  }

  removeSession();
  setUserPermissions();
  closeWSConnection();
  if (redirectToSignIn) {
    auth0Logout({ reason });
  }
  // resetStore();
}

// wrap with once to prevent calling logout on every subsequent requests
export let logout = once(async function () {
  await performLogout({ reason: "notifications:SESSION_CLOSED" });
  channel.broadcast("logout");
  return new Response("Logout");
}, 300);

function onSessionUpdate(session) {
  if (cookieEnabled) {
    setWSAccessToken(session.accessToken);
  }
  authWSConnection();
  window.dispatchEvent(
    new CustomEvent("sessionUpdate", {
      detail: session.refreshTokenExpire || session.accessTokenExpire,
    })
  );
}

export function currentSubTenantSelector(state) {
  return state.currentChildOrganization.subTenantId;
}

async function addSubAuthHeader(request, forceUpdate) {
  let subTenantId = currentSubTenantSelector(store.getState());
  if (subTenantId) {
    if (!subJwts[subTenantId] || forceUpdate) {
      if (!pendingSubAuthRequest) {
        pendingSubAuthRequest = getSubtoken(subTenantId);
      }
      await pendingSubAuthRequest;
      pendingSubAuthRequest = null;
    }
    request.headers.set(httpHeaders.subAuth, subJwts[subTenantId]);
  }
  return request;
}

/**
 * Rotation is only possible if the refresh token exists.
 * - The refresh token can be missed if the user profile has credential policy enforced with session expiration less than 1 day.
 * - If cookie is supported, the refresh token will not be in localStorage but still exists in cookies.
 * - The existence of a refresh token can be understood by the presence of `refreshTokenExpire`.
 */
async function rotateSession() {
  if (rotationPromise) {
    let { data } = await rotationPromise;
    return data;
  }
  try {
    const session = getCurrentSession();
    if (!session || !session.refreshTokenExpire) {
      throw new HTTPError({ statusText: "Unauthorized" });
    }
    channel.broadcast("rotating_session");
    rotationPromise = baseApi
      .post("v1/auth/token", {
        headers: getAuthHeaders(),
        ...(!cookieEnabled &&
          session.refreshToken && {
            json: {
              refreshToken: session.refreshToken,
            },
          }),
      })
      .json();
    let { data } = await rotationPromise;
    setCurrentSession(data);
    onSessionUpdate(data);
    rotationPromise = null;
    channel.broadcast("new_session", data);
    return data;
  } catch (error) {
    return logout(error);
  }
}

export function createApiHooks(withSubtoken) {
  function removeEmptyValues(searchParams) {
    if (!(searchParams instanceof URLSearchParams)) {
      if (searchParams !== null && typeof searchParams === "object") {
        return qs.stringify(searchParams, {
          arrayFormat: "brackets",
          skipNulls: true,
        });
      }
    }
    return searchParams;
  }

  return {
    beforeRequest: [
      async (request, options) => {
        if (withSubtoken) {
          await addSubAuthHeader(request);
        }

        if (options.searchParams) {
          let url = new URL(request.url);
          url.search = new URLSearchParams(
            removeEmptyValues(options.searchParams)
          );
          request = new Request(url, request);
        }

        if (rotationPromise) {
          const { data } = await rotationPromise;

          if (!cookieEnabled) {
            request.headers.set(httpHeaders.auth, `Bearer ${data.accessToken}`);
          }

          return request;
        }

        let now = m().unix();
        let session = getCurrentSession();
        if (
          session === null ||
          (now > session.accessTokenExpire && !session.refreshTokenExpire) ||
          now > session.refreshTokenExpire
        ) {
          return logout();
        }

        const { accessToken } =
          now > session.accessTokenExpire ? await rotateSession() : session;

        if (!cookieEnabled) {
          request.headers.set(httpHeaders.auth, `Bearer ${accessToken}`);
        }

        return request;
      },
    ],
    afterResponse: [parseError],
  };
}

function parseJson(text) {
  try {
    return text && JSON.parse(text);
  } catch {
    return text;
  }
}

export function getAuthHeaders(headers = new Headers()) {
  const { accessToken, tenantId } = getCurrentSession() || {};

  if (!cookieEnabled && accessToken) {
    headers.set(httpHeaders.auth, `Bearer ${accessToken}`);
  }

  if (cookieEnabled && tenantId) {
    headers.set(httpHeaders.tenantId, tenantId);
  }

  return headers;
}

function createAuthorizedApiInstance(withSubtoken) {
  return api.extend({
    headers: getAuthHeaders(),
    hooks: createApiHooks(withSubtoken),
  });
}

async function retryWithNewToken(request) {
  let { accessToken } = await rotateSession();
  request.headers.set(httpHeaders.auth, `Bearer ${accessToken}`);
  return ky(request).catch((error) => {
    if (error.response.status === 401) {
      return logout(error);
    }
  });
}

async function parseError(request, _options, response) {
  if (response.status === 401) {
    if (isCheckpointDomain) {
      return window.location.reload();
    }
    retryWithNewToken(request);
  }

  if (
    response.status === 400 &&
    response.data?.message === "SUB_JWT_NOT_VERIFIED"
  ) {
    try {
      await addSubAuthHeader(request, true);
      return ky(request);
    } catch (error) {
      if (error.response.status === 401) {
        return retryWithNewToken(request);
      }
    }
  }

  if (!response.ok) {
    let error = await response.text();
    try {
      error = JSON.parse(error);
    } catch {
      error = {};
    }
    error.status = response.status;
    error.statusText = response.statusText;
    error.headers = response.headers;

    if (response.status === 504) {
      if (request.method === "GET") {
        showErrorMessage({ response });
      }
      throw new TimeoutError(error);
    }

    // workaround for demo admin role
    if (
      response.status === 409 &&
      error.message === "ADMIN_DEMO_USER_WRITE_FORBIDDEN"
    ) {
      error.data = error.data || {};
      return new Response(JSON.stringify(error), {
        headers: new Headers({ "content-type": "application/json" }),
      });
    }

    throw new HTTPError(error);
  }
}

function createBaseApiInstance() {
  return ky.create({
    prefixUrl:
      env.REACT_APP_API_URL || `https://api.${env.REACT_APP_DOMAIN}/api`,
    timeout: false,
    headers: new Headers({
      [httpHeaders.accept]: "application/json",
    }),
    parseJson,
    ...(cookieEnabled && {
      credentials: "include",
    }),
  });
}

export let cookieEnabled = null;
let baseApi = null;
export let api = null;
export let authorizedApi = null;
export let requestWithSubtoken = null;
let rotationPromise = null;
let finishPendingRotation;
let channel;
let pendingSubAuthRequest;

export let subJwts = {};

export async function getSubtoken(subTenantId) {
  let { data } = await authorizedApi
    .post("v1/auth/org/token", { json: { subTenantId } })
    .json();
  subJwts[subTenantId] = data.subJwt;
  return { data };
}

function createApiInstanceWithSubtoken() {
  return createAuthorizedApiInstance(true);
}

function init() {
  cookieEnabled = env.REACT_APP_ENABLE_SECURITY_COOKIE === "true";
  baseApi = createBaseApiInstance();
  api = baseApi.extend({
    hooks: { afterResponse: [parseError] },
  });
}

function initAuthorized() {
  authorizedApi = createAuthorizedApiInstance();
  requestWithSubtoken = createApiInstanceWithSubtoken();
  const session = getCurrentSession();
  if (session) {
    initWSConnection();

    channel = createBroadcastChannel();
    channel.on("rotating_session", () => {
      rotationPromise = new Promise((resolve) => {
        finishPendingRotation = resolve;
      });
    });

    channel.on("new_session", (session) => {
      onSessionUpdate(session);
      finishPendingRotation(session);
      rotationPromise = null;
      finishPendingRotation = null;
    });

    channel.on("logout", () => {
      performLogout({ reason: "notifications:SESSION_CLOSED" });
    });
  }
}

export default { init, initAuthorized };
