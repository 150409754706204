import detectBrowser from "safervpn-storybook/src/tools/detectBrowser";
import { request as agentsBuildsGetRequest } from "store/api/organization/agentsBuildsGet/agentsBuildsGetActions";
import { useResource } from "hooks";

const windows = "Windows";

let os = detectBrowser ? detectBrowser.os || windows : windows;
let osName = os.replace(/\sOS$|[\s\d.]/g, "").toLowerCase();

function getLink(data) {
  let { agentCdnUrl, link = "" } =
    data?.find(({ name }) => {
      name = name === "macos" ? "mac" : name;
      return name === osName;
    }) || {};
  return agentCdnUrl || link;
}

export function useAgentLink() {
  let { data } = useResource("agentsBuildsGet", agentsBuildsGetRequest);
  if (os.includes(windows)) os = windows;
  return { os, link: getLink(data) };
}
