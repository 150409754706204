import {
  ActionTypes,
  RequestFailureActionCreator,
  setTenantSKUsActionCreator,
} from "./types";

export const requestFailure: RequestFailureActionCreator = (error) => {
  return {
    type: ActionTypes.CP_CHECK_TENANT_SKU_GET_REQUEST_FAILURE,
    error,
  };
};

export const setTenantSKUs: setTenantSKUsActionCreator = (tenantSKUs) => {
  return {
    type: ActionTypes.CP_CHECK_TENANT_SKU_GET_DATA,
    tenantSKUs,
  };
};
