import { omit } from "lodash";
import { cookieEnabled } from "./apiConfigurator";

export const CURRENT_SESSION = "_session";
const ACTIVATE_SUBSCRIPTION_DISMISSED_ON = "activateSubscriptionDismissedOn";
const REMEMBERED_IDP = "rememberedIdp";
const REDIRECTED_TO_IDP = "redirectedToIdp";
const SHOULD_REMEMBER_IDP = "shouldRememberIdp";
const SIGN_IN_STATE = "a0state";
const LOGOUT_REASON = "logoutReason";
const AUTH0_SSO_DATA = "auth0.ssodata";
const SAAS_API_REQUEST_SENT = "saasApiRequestSent";
let SW_SPOG_SRC = "swSpogSrc";
let SW_SSO_TOKEN = "swSSOToken";
let SW_SSO_DOMAIN = "swSSODomain";

export function getCurrentSession() {
  try {
    return JSON.parse(window.localStorage.getItem(CURRENT_SESSION));
  } catch {
    return {};
  }
}

export function setCurrentSession(session) {
  const sessionId = session.sessionId;
  window.sessionStorage.setItem("sessionId", sessionId);
  const sessionValue = cookieEnabled
    ? omit(session, ["accessToken", "refreshToken", "sessionId"])
    : session;
  window.localStorage.setItem(CURRENT_SESSION, JSON.stringify(sessionValue));
}

export function removeSession() {
  window.localStorage.removeItem(CURRENT_SESSION);
  window.localStorage.removeItem(SW_SPOG_SRC);
}

export function getActivateSubscriptionDismissedOn() {
  return window.localStorage.getItem(ACTIVATE_SUBSCRIPTION_DISMISSED_ON);
}

export function setActivateSubscriptionDismissedOn(dismissedOn) {
  window.localStorage.setItem(ACTIVATE_SUBSCRIPTION_DISMISSED_ON, dismissedOn);
}

export function getCurtainSpogSrc() {
  return window.localStorage.getItem(SW_SPOG_SRC);
}

export function setCurtainSpogSrc(src) {
  window.localStorage.setItem(SW_SPOG_SRC, src);
}

export function getSaasApiRequestSent() {
  return window.localStorage.getItem(SAAS_API_REQUEST_SENT);
}

export function setSaasApiRequestSent(tenantId) {
  window.localStorage.setItem(SAAS_API_REQUEST_SENT, tenantId);
}

export function getSWSSOToken() {
  return window.localStorage.getItem(SW_SSO_TOKEN);
}

export function setSWSSOToken(token) {
  window.localStorage.setItem(SW_SSO_TOKEN, token);
}

export function getSWSSODomain() {
  return window.localStorage.getItem(SW_SSO_DOMAIN);
}

export function setSWSSODomain(domain) {
  window.localStorage.setItem(SW_SSO_DOMAIN, domain);
}

export function getRememberedIdp() {
  return window.localStorage.getItem(REMEMBERED_IDP);
}

export function setRememberedIdp(connection) {
  window.localStorage.setItem(REMEMBERED_IDP, connection);
}

export function removeRememberedIdp() {
  window.localStorage.removeItem(REMEMBERED_IDP);
}

export function getRedirectedToIdp() {
  return window.sessionStorage.getItem(REDIRECTED_TO_IDP);
}

export function setRedirectedToIdp() {
  window.sessionStorage.setItem(REDIRECTED_TO_IDP, Date.now());
}

export function removeRedirectedToIdp() {
  window.sessionStorage.removeItem(REDIRECTED_TO_IDP);
}

export function getAndRemoveAuth0SignInState() {
  let value = window.sessionStorage.getItem(SIGN_IN_STATE);
  window.sessionStorage.removeItem(SIGN_IN_STATE);
  return value;
}

export function setAuth0SignInState(state) {
  window.sessionStorage.setItem(SIGN_IN_STATE, state);
}

export function getAndRemoveShouldRememberIdp() {
  let value = window.sessionStorage.getItem(SHOULD_REMEMBER_IDP);
  removeShouldRememberIdp();
  return value;
}

export function setShouldRememberIdp(value) {
  window.sessionStorage.setItem(SHOULD_REMEMBER_IDP, value);
}

export function removeShouldRememberIdp() {
  window.sessionStorage.removeItem(SHOULD_REMEMBER_IDP);
}

export function getLogoutReason() {
  let value = window.sessionStorage.getItem(LOGOUT_REASON);
  window.sessionStorage.removeItem(LOGOUT_REASON);
  return value;
}

export function setLogoutReason(value) {
  window.sessionStorage.setItem(LOGOUT_REASON, value);
}

export function getAuth0SSOData() {
  let ssodata = window.localStorage.getItem(AUTH0_SSO_DATA);
  if (!ssodata) {
    return;
  }
  return JSON.parse(JSON.parse(ssodata));
}
