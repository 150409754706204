import AnalyticsFactory from "./services";

let analyticsFactory = null;

export const analytics = {
  get: () => {
    if (!analyticsFactory) {
      analyticsFactory = new AnalyticsFactory();
    }
    return analyticsFactory.analyticsEvents;
  }
};

export function withTrack(submit, payload) {
  return function (event) {
    analytics.get().track(payload, event);
    return submit(event);
  };
}

export function initGTM() {
  window["dataLayer"] = [
    { "gtm.start": new Date().getTime(), event: "gtm.js" },
  ];
}

function _pushEvent(event, data) {
  if (!Array.isArray(window["dataLayer"])) {
    return;
  }
  window["dataLayer"].push({
    event,
    data,
  });
}

export function trackMembersInvite(numofinvites) {
  _pushEvent("members-invite", { numofinvites });
}

export function trackMembersInviteError(error) {
  _pushEvent("members-invite-error", { error });
}

export function trackChangeRole(previousrole, newrole) {
  _pushEvent("change-role", { previousrole, newrole });
}

export function trackChangeRoleError(previousrole, newrole, error) {
  _pushEvent("change-role-error", { previousrole, newrole, error });
}

export function trackAdminResendInvite() {
  _pushEvent("admin-resend-invite");
}

export function trackAdminResetPassword() {
  _pushEvent("admin-reset-password");
}

export function trackPasswordRequestSent() {
  _pushEvent("password-request-sent");
}

export function trackPasswordSelectNew() {
  _pushEvent("password-select-new");
}

export function trackPasswordChanged() {
  _pushEvent("password-changed");
}

export function trackAdminInviteLink() {
  _pushEvent("admin-invite-link");
}

export function trackMemberRemove() {
  _pushEvent("member-remove");
}

export function trackGroupUserAdded() {
  _pushEvent("group-user-added");
}

export function trackGroupUserAddedError(error) {
  _pushEvent("group-user-added-error", { error });
}

export function trackGroupCreated() {
  _pushEvent("group-created");
}

export function trackGroupCreatedError() {
  _pushEvent("group-created");
}

export function trackServerRemoved() {
  _pushEvent("server-removed");
}

export function trackServerDeployed(error) {
  _pushEvent("server-deployed", { error });
}

export function trackLocationRemoved(error) {
  _pushEvent("locationrver-removed", { error });
}

export function trackLocationCreated(error) {
  _pushEvent("location-created", { error });
}

export function trackCreditCardUpdatedError(error) {
  _pushEvent("credit-card-updated-error", { error });
}

export function trackСreditCardUpdated() {
  _pushEvent("credit-card-updated-success");
}

export function trackPlanUpgradedYearlyError(error) {
  _pushEvent("plan-upgraded-yearly-error", { error });
}

export function trackPlanUpgradedYearly() {
  _pushEvent("plan-upgraded-yearly-success");
}

export function trackMemberLicensesBuy(numoflicenses) {
  _pushEvent("member-licenses-buy", { numoflicenses });
}

export function trackMemberLicensesBuyError(numoflicenses, error) {
  _pushEvent("member-licenses-buy-error", { numoflicenses, error });
}

export function trackServerLicensesBuy(numoflicenses) {
  _pushEvent("server-licenses-buy", { numoflicenses });
}

export function trackServerLicensesBuyError(numoflicenses, error) {
  _pushEvent("server-licenses-buy", { numoflicenses, error });
}

export function trackMemberActivationClicked(email) {
  _pushEvent("member-activation-clicked", { email });
}

export function trackMemberActivationCompleted() {
  _pushEvent("member-activation-completed");
}

export function learnMoreClickHandler(eventName, tag) {
  return () => analytics.get().track({
    event_name: eventName,
    tag: tag,
  });
}

export * from './eventsData'