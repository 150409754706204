import { useCallback, useMemo, useState } from "react";
import type { ReactNode, MouseEventHandler } from "react";
import { createPortal } from "react-dom";
import { useToggle } from "react-use";
import i18n from "i18next";
import DOMPurify from "dompurify";
import { styled } from "@mui/material/styles";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogProps } from "@mui/material";
import {
  Button,
  openModal,
  closeModal,
  Toaster as DSToaster,
} from "@perimeter_81/ui";
import type { AvatarProps, ModalProps, ToasterProps } from "@perimeter_81/ui";
import { links } from "data/constants";

export type ConfirmationModalProps = Omit<DialogProps, "open" | "onClose"> & {
  isDeleteModal?: boolean;
  avatarProps?: AvatarProps;
  confirmationIconSrc?: string;
  confirmationTitle?: ReactNode;
  confirmation?: ReactNode;
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  dialogActionsProps?: ModalProps["dialogActionsProps"];
  handleCancel?: MouseEventHandler;
  handleConfirm?: MouseEventHandler;
};

type StyledDialogContentTextProps = {
  isDeleteModal?: boolean;
};

const StyledDialogContentText = styled(DialogContentText, {
  shouldForwardProp: (prop) => prop !== "isDeleteModal",
})<StyledDialogContentTextProps>(({ isDeleteModal, theme }) => ({
  "& em": {
    fontStyle: "normal",
    color: isDeleteModal
      ? theme.palette.error[500]
      : theme.palette.secondary[500],
  },
}));

export function showConfirmationModal(props: ConfirmationModalProps) {
  const {
    confirmationTitle,
    confirmation,
    confirmButtonText,
    cancelButtonText,
    isDeleteModal,
    confirmationIconSrc = isDeleteModal
      ? `${links.imagesBaseUrl}/icons2/icon_trash.svg`
      : null,
    avatarProps,
    dialogActionsProps,
    handleCancel,
    handleConfirm,
    ...other
  } = props;

  function onClick(callback: MouseEventHandler): MouseEventHandler {
    return function handler(event) {
      callback?.(event);
      closeModal();
    };
  }

  openModal({
    avatarProps: avatarProps,
    dialogTitleProps: {
      icon: confirmationIconSrc && {
        src: confirmationIconSrc,
      },
      children: confirmationTitle,
    },
    dialogContentProps: {
      children: (
        <StyledDialogContentText isDeleteModal={isDeleteModal}>
          {typeof confirmation === "string" ? (
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(confirmation),
              }}
            />
          ) : (
            confirmation
          )}
        </StyledDialogContentText>
      ),
    },
    dialogActionsProps: dialogActionsProps || {
      children: (
        <>
          {cancelButtonText && (
            <Button onClick={onClick(handleCancel)} color="third">
              {cancelButtonText}
            </Button>
          )}
          {confirmButtonText && (
            <Button
              onClick={onClick(handleConfirm)}
              color={isDeleteModal ? "error" : "primary"}
            >
              {confirmButtonText}
            </Button>
          )}
        </>
      ),
    },
    ...other,
  });
}

export function showConflictMessage(options) {
  const { title, description } = options;
  openModal({
    dialogTitleProps: {
      children: title,
    },
    dialogContentProps: {
      children: description,
    },
    dialogActionsProps: {
      children: (
        <Button
          color="primary"
          onClick={() => {
            closeModal();
          }}
        >
          {i18n.t("common:OK")}
        </Button>
      ),
    },
  });
}

type ToasterOptions = {
  severity: ToasterProps["severity"];
  message: ReactNode;
};

export function useShowNotification() {
  const [open, toggleOpen] = useToggle(false);
  const [toasterProps, setToasterProps] = useState<ToasterOptions>({
    severity: "success",
    message: "",
  });

  const rootEl = useMemo(() => document.getElementById("root"), []);

  const displayToaster = useCallback(
    (options: ToasterOptions) => {
      setToasterProps(options);
      toggleOpen();
    },
    [toggleOpen]
  );

  return {
    displayToaster,
    Toaster:
      rootEl &&
      createPortal(
        <DSToaster
          open={open}
          onClose={toggleOpen}
          autoHideDuration={10000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          severity={toasterProps.severity}
        >
          {toasterProps.message}
        </DSToaster>,
        rootEl
      ),
  };
}
