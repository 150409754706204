import { ConfirmationModalProps, showConfirmationModal } from "./notification";
import { iconSrc } from "./url";

export function getUserConfirmation(
  message: string,
  callback: (allowTransition: boolean) => void
) {
  let parsed: ConfirmationModalProps;
  try {
    parsed = JSON.parse(message);
  } catch (error) {
    parsed = {
      confirmationTitle: "",
      confirmation: message,      
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      isDeleteModal: false,
    };
  }

  const {
    confirmationTitle,
    confirmation,
    confirmButtonText,
    cancelButtonText,
    confirmationIconSrc = iconSrc("icon_infoBig"),
    isDeleteModal,
  } = parsed;

  showConfirmationModal({
    confirmationTitle,
    confirmation,
    confirmButtonText,
    cancelButtonText,
    confirmationIconSrc,
    isDeleteModal,
    handleConfirm: () => {
      window.dispatchEvent(new CustomEvent("ReactRouterPromptConfirm"));
      callback(true);
    },
    handleCancel: () => {
      window.dispatchEvent(new CustomEvent("ReactRouterPromptCancel"));
      callback(false);
    },
  });
}
